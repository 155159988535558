import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { red } from '@mui/material/colors'
import { useHistory } from 'react-router'
import { LoginData } from 'use/Interface/Interface'

interface Props {
    open: boolean
    forcePasswordChange: boolean
    setForcePasswordChange: React.Dispatch<React.SetStateAction<boolean>>
    setPasswordExpireWarning: React.Dispatch<
        React.SetStateAction<{ status: boolean; numberOfDaysRem: number; oneTimeShow: boolean }>
    >
    passwordExpireWarning: { status: boolean; numberOfDaysRem: number; oneTimeShow: boolean }
    loginData: LoginData
    setLoginData: React.Dispatch<React.SetStateAction<LoginData>>
    forceLogout: any
}

const PasswordExpireWarning: React.FC<Props> = ({
    open,
    forcePasswordChange,
    setForcePasswordChange,
    setPasswordExpireWarning,
    passwordExpireWarning,
    loginData,
    setLoginData,
    forceLogout,
}) => {
    const history = useHistory()

    const onChangePassword = () => {
        history.push('change-password')
        setPasswordExpireWarning({
            status: false,
            numberOfDaysRem: passwordExpireWarning.numberOfDaysRem,
            oneTimeShow: true,
        })
        if (passwordExpireWarning.status) {
            setForcePasswordChange(false)
            setLoginData({ ...loginData, password_warning_accept: true })
            sessionStorage.setItem('loginData', JSON.stringify({ ...loginData, password_warning_accept: true }))
        }
    }

    const onCancelPassword = () => {
        setPasswordExpireWarning({
            status: false,
            numberOfDaysRem: passwordExpireWarning.numberOfDaysRem,
            oneTimeShow: true,
        })
        if (passwordExpireWarning.status) {
            setLoginData({ ...loginData, password_warning_accept: true })
            sessionStorage.setItem('loginData', JSON.stringify({ ...loginData, password_warning_accept: true }))
        }
        if (forcePasswordChange) {
            forceLogout()
        }
    }

    return (
        <>
            <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle
                    id="alert-dialog-title"
                    style={{
                        backgroundColor: red[400],
                        padding: 0,
                        paddingLeft: 24,
                        paddingTop: 8,
                        paddingBottom: 8,
                        marginBottom: 8,
                        color: 'white',
                    }}
                >
                    Change password
                </DialogTitle>
                <DialogContent>
                    {forcePasswordChange
                        ? `Your password has expired. Please change your password`
                        : `Your password will expire in ${passwordExpireWarning?.numberOfDaysRem} days. Would you like to change your password?`}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" autoFocus onClick={onChangePassword}>
                        Change Password
                    </Button>
                    <Button color="secondary" autoFocus onClick={onCancelPassword}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PasswordExpireWarning
