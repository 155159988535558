import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import logo from 'assets/logo.png'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import Footer from 'components/Footer/Footer'
import Navbar from 'components/Navbar/Navbar'
import React from 'react'
import Helmet from 'react-helmet'
import Loadable from 'react-loadable'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import theme from 'theme/theme'
import { ContextProvider } from 'use/Context/ContextApi'
import Loader from 'use/Loader/Loader'

const Signup = Loadable({
    loader: () => import('pages/Signup/Signup'),
    loading: Loader,
})

const Login = Loadable({
    loader: () => import('pages/LoginPage/LoginPage'),
    loading: Loader,
})

const ResetPassword = Loadable({
    loader: () => import('pages/ResetPassword/ResetPassword'),
    loading: Loader,
})

const MFAConfig = Loadable({
    loader: () => import('pages/MFAConfig/MFAConfig'),
    loading: Loader,
})

const ForgotPassword = Loadable({
    loader: () => import('pages/ForgotPasswordPage/ForgotPasswordPage'),
    loading: Loader,
})

const ConfirmForgotPassword = Loadable({
    loader: () => import('pages/ConfirmForgetPassword/ConfirmForgetPassword'),
    loading: Loader,
})

const ForgotUsername = Loadable({
    loader: () => import('pages/ForgotUsernamePage/ForgotUsernamePage'),
    loading: Loader,
})

const Home = Loadable({
    loader: () => import('pages/Home/Home'),
    loading: Loader,
})

const Dashboard = Loadable({
    loader: () => import('components/Dashboard/Dashboard'),
    loading: Loader,
})

const KYCInfo = Loadable({
    loader: () => import('components/UserInfo/UserInfoWrapper'),
    loading: Loader,
})

const CompleteRegistration = Loadable({
    loader: () => import('pages/CompleteRegistration/CompleteRegistration'),
    loading: Loader,
})

const KYBInfo = Loadable({
    loader: () => import('pages/KYBInfo/KYBInfo'),
    loading: Loader,
})

const VerifyMFA = Loadable({
    loader: () => import('pages/VerifyMFA/VerifyMFA'),
    loading: Loader,
})
const NegativeScreeningKYB = Loadable({
    loader: () => import('pages/NegativeScreening/NegativeScreeningKYB'),
    loading: Loader,
})
const PageNotFound = Loadable({
    loader: () => import('pages/PageNotFound/404'),
    loading: Loader,
})

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet>
                <link rel="icon" property="og:image" type="image/png" href={logo} sizes="16x16" />
                {/* <meta
                    name="description"
                    content="Custom White label neo-banking solution brought to you by CFS-ZIPP TECHNOLOGIES"
                /> */}
            </Helmet>
            <ErrorBoundary>
                <div className="App" style={{ minHeight: '100vh' }}>
                    <Router>
                        <ContextProvider>
                            <Navbar />

                            <div className="">
                                <Switch>
                                    <Route exact path="/" component={Home} />
                                    <Route exact path="/login" component={Login} />
                                    <Route exact path="/signup" component={Signup} />
                                    <Route exact path="/forgot-password" component={ForgotPassword} />
                                    <Route
                                        path="/confirm-password/:username/:token"
                                        component={ConfirmForgotPassword}
                                    />
                                    <Route exact path="/reset-password" component={ResetPassword} />
                                    <Route exact path="/configure-mfa" component={MFAConfig} />
                                    <Route exact path="/forgot-username" component={ForgotUsername} />
                                    <Route exact path="/user-profile" component={Dashboard} />
                                    <Route exact path="/user-info" component={Dashboard} />
                                    <Route exact path="/document-validity" component={Dashboard} />
                                    <Route exact path="/permission-screen" component={Dashboard} />
                                    <Route exact path="/set-user-permission" component={Dashboard} />
                                    <Route exact path="/providers" component={Dashboard} />
                                    <Route exact path="/entity-onboarding" component={Dashboard} />
                                    <Route exact path="/configurator" component={Dashboard} />
                                    <Route exact path="/create-user" component={Home} />
                                    <Route exact path="/entity-update" component={Home} />
                                    <Route exact path="/kyc-approval" component={Dashboard} />
                                    <Route exact path="/kyb-upload" component={Dashboard} />
                                    <Route exact path="/kyb-approval" component={Dashboard} />
                                    <Route exact path="/accounts-fulfillment" component={Dashboard} />
                                    <Route exact path="/entity-approval" component={Dashboard} />
                                    <Route exact path="/sys-admin" component={Dashboard} />
                                    <Route exact path="/wallets" component={Home} />
                                    <Route exact path="/payments-transfer" component={Home} />
                                    <Route exact path="/to-my-other-account" component={Home} />
                                    <Route exact path="/to-someone-else" component={Home} />
                                    <Route exact path="/uk-domestic-transfer" component={Home} />
                                    <Route exact path="/euro-transfer" component={Home} />
                                    <Route exact path="/international-transfer" component={Home} />
                                    <Route exact path="/multi-currency" component={Home} />
                                    <Route exact path="/accounts" component={Home} />
                                    <Route exact path="/accounts" component={Home} />
                                    <Route exact path="/request-new-account" component={Home} />
                                    <Route exact path="/gbp-account-1" component={Home} />
                                    <Route exact path="/gbp-account-2" component={Home} />
                                    <Route exact path="/usd-account-1" component={Home} />
                                    <Route exact path="/usd-account-2" component={Home} />
                                    <Route exact path="/statements" component={Home} />
                                    <Route exact path="/settings" component={Home} />
                                    <Route exact path="/change-email" component={Home} />
                                    <Route exact path="/change-password" component={Home} />
                                    <Route exact path="/change-phone" component={Home} />
                                    <Route exact path="/change-personal-info" component={Home} />
                                    <Route exact path="/kyc-details" component={Home} />
                                    <Route exact path="/kyc-info" component={KYCInfo} />
                                    <Route exact path="/accounts-approval" component={Dashboard} />
                                    <Route exact path="/complete-registration" component={CompleteRegistration} />
                                    <Route exact path="/kyb-info" component={KYBInfo} />
                                    <Route exact path="/accounts-approval-journey" component={Dashboard} />
                                    <Route exact path="/mappings" component={Dashboard} />
                                    <Route exact path="/staffusers-mapping" component={Dashboard} />
                                    <Route exact path="/accounts-mapping" component={Dashboard} />
                                    <Route exact path="/accounts-staffusers-mapping" component={Dashboard} />
                                    <Route exact path="/accounts-info" component={Dashboard} />
                                    <Route exact path="/payment-approval" component={Dashboard} />
                                    <Route exact path="/approvals" component={Dashboard} />
                                    <Route exact path="/approvals-journey" component={Dashboard} />
                                    <Route exact path="/corporate-details" component={Dashboard} />
                                    <Route exact path="/transactions-review" component={Dashboard} />
                                    <Route exact path="/user-transactions" component={Home} />
                                    <Route exact path="/processed-transactions" component={Dashboard} />
                                    <Route exact path="/verify-mfa" component={VerifyMFA} />
                                    <Route exact path="/price-list" component={Home} />
                                    <Route exact path="/kyc-approval-journey" component={Dashboard} />
                                    <Route exact path="/kyb-approval-journey" component={Dashboard} />
                                    <Route exact path="/payment-approval-journey" component={Dashboard} />
                                    <Route exact path="/configuration" component={Dashboard} />
                                    <Route exact path="/approval-rules" component={Dashboard} />
                                    <Route exact path="/user-role-permission" component={Dashboard} />
                                    <Route exact path="/deactivate-mfa" component={Dashboard} />
                                    <Route exact path="/existing-accounts-approval" component={Dashboard} />
                                    <Route exact path="/reports" component={Dashboard} />
                                    <Route exact path="/session-log" component={Dashboard} />
                                    <Route exact path="/batch-payments" component={Home} />
                                    <Route exact path="/inactive-accounts" component={Home} />
                                    <Route exact path="/refund-report" component={Dashboard} />
                                    {/* <Route exact path="/contact-support" component={Home} /> */}
                                    <Route exact path="/documentation" component={Home} />
                                    <Route exact path="/ledger-transactions" component={Home} />
                                    <Route exact path="/entity360" component={Dashboard} />
                                    <Route exact path="/entity" component={Dashboard} />
                                    <Route exact path="/configurator-setting" component={Home} />
                                    <Route exact path="/profile-setting" component={Home} />
                                    <Route exact path="/validate-kyb" component={NegativeScreeningKYB} />
                                    <Route exact path="/help" component={Home} />
                                    <Route exact path="/corporate-portal" component={Dashboard} />
                                    <Route exact path="/create-corporate" component={Dashboard} />
                                    <Route exact path="/request-iban" component={Dashboard} />
                                    <Route exact path="/onboard-corporate" component={Dashboard} />
                                    <Route exact path="/upload-kyb-documents" component={Dashboard} />
                                    <Route exact path="/corporate-user-invite" component={Dashboard} />
                                    <Route exact path="/fee-sharing" component={Dashboard} />
                                    <Route exact path="/notification-settings" component={Home} />
                                    <Route exact path="/receipt-approval" component={Dashboard} />
                                    <Route exact path="*" component={PageNotFound} />
                                </Switch>
                            </div>
                        </ContextProvider>
                    </Router>
                </div>
            </ErrorBoundary>

            <Footer />
        </ThemeProvider>
    )
}

export default App
