import React from 'react'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
    message: string
    severity: 'warning' | 'error' | 'success' | 'info'
}

const Announcement: React.FC<Props> = (props) => {
    const [open, setOpen] = React.useState(true)

    return (
        <div>
            <Collapse in={open}>
                <Alert
                    style={{ fontSize: '17px' }}
                    action={
                        <IconButton
                            aria-label="close"
                            size="small"
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={props.severity}
                >
                    {props.message}
                </Alert>
            </Collapse>
        </div>
    )
}

export default Announcement
