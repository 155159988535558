import React from 'react'
import ReactDOM from 'react-dom'

import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import './style'
import App from './App'
import reportWebVitals from './reportWebVitals'


if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
  }

ReactDOM.render(
    <React.Fragment>
        <App />
    </React.Fragment>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
