import React, { Component, ReactNode } from 'react'
import ErrorBoundaryComponent from './ErrorBoundaryComponent'

interface ErrorBoundaryProps {
    children: ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({ hasError: true })
        // You can log the error to an error reporting service
        console.error('Error caught by error boundary:', error, errorInfo)
    }

    render(): ReactNode {
        if (this.state.hasError) {
            // You can render a fallback UI here
            return <ErrorBoundaryComponent />
        }

        return this.props.children
    }
}

export default ErrorBoundary
