import { Box, Container, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import ErrorImage from '../../assets/error.svg'

const ErrorBoundaryComponent = () => {
    const history = useHistory()
    return (
        <Container style={{ height: '100vh' }}>
            <Box alignItems="center" display="flex" height="100%" justifyContent="center" flexDirection="column">
                <img src={ErrorImage} alt="" height={300} />
                <Typography color="tomato" variant="h5" mt={4} fontWeight="bold">
                    Something went wrong
                </Typography>
                {/* <Typography variant="h3" fontWeight={500}>
                    We lost this page
                </Typography> */}
                <Typography variant="subtitle1" color="turquoise" textAlign="center" mt={2}>
                    Please refresh your page or try again later.
                </Typography>
                {/* <Typography variant="subtitle2" color="grey" textAlign="center" mt={1} mb={2}>
                    Let's find a better place for you to go.
                </Typography> */}
            </Box>
        </Container>
    )
}
export default ErrorBoundaryComponent
