export enum SignupFlow {
    ACCOUNT_TYPE = 'accountType',
    INDIVIDUAL_ACCOUNT = 'individualAccount',
    CORPORATE_ACCOUNT = 'corporateAccount',
}

export enum ApiEndpoint {
    SIGNUP = 'signup',
    LOGIN = 'login',
    LOGOUT = 'logout',
    REFRESH_TOKEN = 'auth-refresh',
    FORGET_PASSWORD = 'forgot-password',
    FORGET_USERNAME = 'forgot-username',
    RESET_PASSWORD = 'reset-password',
    CONFIRM_FORGET_PASSWORD = 'confirm-forgot-password',
    REGISTER_COMPANY = 'register-company',
    CORPORATE_STAFF_REGISTRATION = 'register-corporate-staff',
    PM_SPM_STAFF_REGISTRATION = 'register-pm-spm-staff',
    COUNTRY_LIST = 'get-country-list',
    GET_PARAMETER = 'get-parameter',
    SET_PARAMETER = 'set-parameter',
    CREATE_PERMISSION = 'create-permission',
    UPDATE_PERMISSION = 'edit-permission',
    DELETE_PERMISSION = 'delete-permission',
    FETCH_PERMISSION = 'fetch-permission',
    COMPANY_PARENT_LIST = 'company-parent-full-relation',
    GET_USER_PROFILE = 'get-user-profile',
    UPDATE_USER_PROFILE = 'update-user-profile',
    GET_CUSTOMER_FILE_PRE_SIGNED_URL = 'get-customer-file-pre-signed-url',
    GET_STAFF_FILE_PRE_SIGNED_URL = 'get-staff-file-pre-signed-url',
    CREATE_DOCUMENT_TYPE_VALIDITY = 'create-document-type-validity',
    FETCH_DOCUMENT_TYPE_VALIDITY = 'fetch-document-type-validity',
    DELETE_DOCUMENT_TYPE_VALIDITY = 'delete-document-type-validity',
    INSERT_KYC_DOCUMENT_STATUS = 'insert-kyc-document-status',
    FETCH_USER_PERMISSIONS = 'fetch-user-permission',
    SET_USER_PERMISSIONS = 'set-user-permission',
    GET_ACCEPTABLE_KYC_DOCUMENT_BY_USER = 'get-acceptable-kyc-document-by-user',
    KYC_DOCS_STATISTICS = 'get-kyc-docs-needed-uploaded-by-user',
    GET_OBJECT_SIGNED_URL = 'get-object-signed-url',
    CHANGE_PASSWORD = 'change-password',
    KYC_ACCEPT = 'kyc-accept',
    KYC_REJECT = 'kyc-reject',
    GET_KYC_APPROVAL_PENDING_DOCUMENT = 'get-kyc-approval-pending-document',
    GET_COMPANY_FILE_PRE_SIGNED_URL = 'get-company-file-pre-signed-url',
    CREATE_PROVIDER = 'create-provider',
    INSERT_KYB_DOCUMENT_STATUS = 'insert-kyb-document-status',
    GET_USER_ATTRIBUTE = 'get-user-attributes',
    CREATE_ACCOUNT = 'create-account',
    GET_USER_PROFILES_BY_ENTITY = 'get-user-profiles-by-entity',
    GET_PROVIDER_DETAILS = 'get-provider-details',
    GET_All_USER_PROFILE_DETAILS = 'get-all-profiles-by-username',
    UPDATE_USER_BAND_TYPE = 'update-user-band-type',
    GET_KYB_APPROVAL_PENDING_DOCUMENT = 'get-kyb-approval-pending-document',
    GET_DOCUMENT_APPROVAL_RULES = 'get-document-approval-rules',
    GET_USER_PROFILE_SCREEN_2 = 'get-user-profiles-screen-2',
    KYB_ACCEPT = 'kyb-accept',
    KYB_REJECT = 'kyb-reject',
    GET_KYB_DOCS_UPLOADED_SO_FAR = 'get-kyb-docs-uploaded-so-far',
    GET_ALL_ENTITY_DETAILS = 'get-all-entity-details',
    UPDATE_ENTITY_DETAILS = 'update-company-detail',
    GET_PENDING_ACCOUNTS = 'get-pending-accounts',
    GET_BANK_CODE_TYPE = 'get-bank-code-type',
    UPDATE_ACCOUNT_BY_STAFF = 'update-account-by-staff',
    ACCOUNT_ACCEPT = 'account_accept',
    ACCOUNT_REJECT = 'account_reject',
    GET_ONBOARDING_KYB_STATUS_LIST = 'get-entity-onboarding-kyb-status',
    UPDATE_ONBOARDING_STATUS = 'update-entity-onboarding-status',
    UPDATE_KYB_STATUS = 'update-entity-kyb-status',
    BANK_ACCOUNT_ACCEPT = 'bank-account-accept',
    BANK_ACCOUNT_REJECT = 'bank-account-reject',
    EDIT_ACCOUNT_ACCEPT = 'edit-account-accept',
    EDIT_ACCOUNT_REJECT = 'edit-account-reject',
    GET_ACCOUNT_APPROVAL_PENDING_DOCUMENT = 'get-account-approval-pending-document',
    GET_ACCOUNT_USERNAME_TYPE_BY_ENTITY_ID = 'get-account-username-type-by-entity-id',
    GET_ACCOUNT_NAME_TYPE_CURRENCY_FROM_ACCOUNTS = 'get-account-name-type-currency-from-accounts',
    GET_ACCOUNT_ID_FROM_ACCOUNT_GROUPS = 'get-account-id-from-account-groups',
    INSERT_STAFF_GROUP4ACCT_ACCESS = 'insert-staff-groups4acct-access',
    INSERT_ACCOUNT_GROUPS = 'insert-account-groups',
    GET_STAFF_GROUP4ACCT_ACCESS = 'get-staff_groups4acct_access',
    GET_ACCOUNT_GROUP_LISTING = 'get-account-group-listing',
    INVALIDATE_CLOUDFRONT = 'invalidate-all-cloudfront-distributions',
    GET_ENTITY_DETAIL_COMPARISON = 'get-entity-details-old-and-new',
    INSERT_STAFF_GROUP4ACCT_ACCOUNT_GROUPS = 'insert-staff-group4acct-account-groups',
    GET_TRANSACTION_FEE = 'get-transaction-fee',
    GET_PROVIDER_RELATED_TRANSACTION_DETAILS = 'get-provider-related-transaction-details',
    GET_ALL_ACCOUNTS_INFO = 'get-all-accounts-info',
    CHANGE_EMAIL = 'update-user-email-address',
    UPDATE_USER_ATTRIBUTE = 'update-user-attribute',
    GET_BALANCES_BYACCT_BYCURRENCY = 'get-balances-byacct-bycurrency',
    FETCH_ALL_MCCY_CB_FX_IBANS = 'fetch-all-mccy-cb-fx-ibans',
    CONFIRM_CHANGE_EMAIL = 'confirm-update-user-email-address',
    GET_CUSTOMER_KYC_DETAILS = 'get-customer-kyc-details-view',
    DELETE_STAFF_USER_GROUP = 'delete-staff-user-group',
    DELETE_A_USER_IN_STAFF_USER_GROUP = 'delete-a-user-in-staff-user-group',
    DELETE_ACCOUNT_GROUP = 'delete-account-group',
    DELETE_ACCOUNT_LIST = 'delete-account-list',
    GET_ACCTGROUP_STAFFGROUP_NAME = 'get-acctgroup-staffgroup-name',
    GET_RECENT_TRANSACTION_BY_IBAN = 'get-recent-transactions-by-iban',
    GET_RECENT_TEN_TRANSACTION = 'get-recent-ten-transactions',
    DELETE_FROM_ACCOUNT_STAFF_USER_BY_SG4ACCT_ACCESS_GROUP_NAME = 'delete-from-account-staff-user-by-sg4acct-access-group-name',
    CREATE_TRANSACTION_TO_SELF = 'create-transaction-to-self',
    GET_TRANSACTIONS_FOR_APPROVAL = 'get-transactions-for-approval',
    TRANSACTION_APPROVE = 'transaction-approve',
    TRANSACTION_REJECT = 'transaction-reject',
    BULK_TRANSACTION_APPROVE = 'bulk-transaction-approve',
    BULK_TRANSACTION_REJECT = 'bulk-transaction-reject',
    CREATE_TRANSACTION_TO_ANOTHER_CLIENT = 'create-transaction-to-another-client',
    CREATE_TRANSACTION_TO_UK_DOMESTIC = 'create-transaction-in-uk',
    CREATE_TRANSACTION_MCCY = 'create-transaction-mccy',
    GET_ENTITY_LEVEL_NOTIFICATIONS = 'get-entity-level-notifications',
    GET_FEE_ACCOUNT_LIST = 'get-fee-account-list',
    GET_STATEMENT_FROMDATE_TODATE = 'get-statement-fromdate-todate',
    GET_ENTITY_DETAILS = 'get-entity-details',
    USER_TRANSACTIONS = 'get-user-transactions-by-entityId',
    GET_ALL_IBANS_FOR_ENTIRYID = 'get-all-ibans-for-entityid',
    PROCESSED_TRANSACTIONS = 'get-processed-transactions-by-entityId',
    GET_PROCESSED_TRANSACTIONS_FROM_DATE_TO_DATE_BY_ENTITYID = 'get-processed-transactions-from-date-to-date-by-entityId',
    GET_USER_TRANSACTIONS_FROM_DATE_TO_DATE_BY_ENTITYID = 'get-user-transactions-from-date-to-date-by-entityId',
    KYB_APPROVAL_EMAIL = 'kyb-approval-email',
    GET_ACCOUNT_LIST = 'get-balances-byacct-bycurrency-by-username',
    CREATE_FX_TRANSACTION = 'create-fx-transaction',
    GET_STATEMENT_FOOTER = 'get-statement-footer',
    GET_USER_TRANSACTIONS = 'get-user-transactions-wallet-user',
    GENERATE_MFA_KEY = 'generate-mfa-secret-key',
    VERIFY_MFA_DEVICE = 'verify-mfa-device',
    VERIFY_MFA_OTP = 'verify-mfa-otp',
    ACCOUNT_LIST_FOR_CORPORATE_ENTITY = 'get-balances-byacct-bycurrency-bycorporate',
    GET_PRICELIST = 'get-pricelist',
    ADD_PRICELIST = 'add-pricelist',
    REMOVE_PRICELIST = 'remove-pricelist',
    ADD_TNX_FEE_PRICELIST = 'add-txn-fee-pricelist',
    GET_TNX_FEE_PRICELIST = 'get-txn-fee-pricelist',
    REMOVE_TNX_FEE_PRICELIST = 'remove-txn-fee-pricelist',
    ENTITY_APPROVAL_EMAIL = 'entity-onboarding-complete-email',
    REQUEST_MORE_KYB = 'ask-more-kyb-documents',
    ONBOARDING_INCOMPLETE = 'onboarding-incomplete',
    GET_TNX_FEE_PRICELIST_NAMES = 'get-txn-fee-pricelist-names',
    EURO_TRANSACTION = 'create-eur-transaction',
    GET_USER_ACTION_APPROVAL_RULES = 'get-user-action-approval-rules',
    INSERT_USER_ACTION_APPROVAL_RULES = 'insert-user-action-approval-rules',
    DEACTIVATE_DEVICE_MFA = 'deactivate-mfa-device',
    GET_ALL_ACCOUNTS_FOR_ENTITY = 'get-all-accounts-for-entity',
    GET_ALL_EDIT_ACCOUNTS_FOR_ENTITY = 'get-all-edit-accounts-for-entity',
    EDIT_ACCOUNT_APPROVAL = 'edit-account-approval',
    GET_IP_REPORTS_FROMDATE_TODATE = 'get-user-login-forensics',
    INSERT_FREQUENT_PAYEE = 'insert-frequent-payee-list',
    GET_FREQUENT_PAYEE_LIST = 'get-frequent-payee-list',
    INSERT_BATCH_PAYMENT_UPLOAD_STAGING = 'insert-batch-payment-upload-staging',
    BULK_UK_DOMESTIC_TRANSFER = 'bulk-uk-domestic-transfer',
    BULK_EURO_TRANSFER = 'bulk-euro-transfer',
    DOWNLOAD_BATCH_PAYMENTS_TEMPLATE = 'download-batch-payments-template',
    EXTEND_ACCOUNT_GROUPS_LIST = 'extend-account-groups-list',
    EXTEND_USER_GROUPS_LIST = 'extend-user-groups-list',
    GET_FEE_ACCOUNT_DETAILS = 'get-fee-account-details',
    GET_ALL_ROLE_MENU_SUBMENU = 'get-all-role-menu-submenu',
    INSERT_ROLES = 'insert-roles',
    GET_ROLES = 'get-roles',
    REMOVE_ROLE = 'remove-role',
    DELETE_APPROVAL_RULE = 'delete-approval-rule',
    GET_APPROVAL_COUNT_FOR_ALL_LEVELS = 'get-approval-count-for-all-levels',
    GET_INACTIVE_ACCOUNTS = 'get-inactive-accounts',
    GET_REFUND_FEE_REPORT = 'get-refund-fee-report',
    CB_FX_GET_MARKETRATE = 'cb-fx-get-marketrate',
    SEND_SUPPORT_EMAIL = 'send-support-email',
    RETURN_MENU_SUBMENU_FOR_ROLE = 'return-menu-submenu-for-role',
    GET_LEDGER_TRANSACTIONS = 'get-ledger-transactions',
    TEST_SMTP_CONNECTION = 'smtp-setup-testing',
    UPLOAD_SOF_DOCUMENT_PRE_SIGNED_URL = 'upload-sof-document-pre-signed-url',
    UPDATE_SUPPORTING_DOCUMENT_DETAILS = 'update-supporting-documents-details',
    GET_SUPPORTING_DOCUMENTS = 'get-supporting-documents',
    SOF_REQUESTED_EMAIL = 'sof-requested-email',
    EDIT_USER_ATTRIBUTE = 'update-user-role',
    GET_ACCT_USER_MAP_LISTING = 'get-acct-user-map-listing',
    BULK_MCCY_TRANSFER = 'bulk-mccy-transfer',
    GET_RECAPTCHA_CONFIRMATION = 'get-recaptcha-confirmation',
    GET_ACCOUNT_INFO_BY_ACCOUNT_ID = 'get-account-info-by-account-id',
    GET_DAILY_BEGIN_END_BALANCE_REPORT = 'get-daily-begin-end-balance-report',
    GET_REPORT_NAMES_LIST = 'get-report-names-list',
    GET_VOLUME_FROMDATE_TODATE = 'get-volume-fromdate-todate',
    GET_DAILY_TRANSACTIONS_REPORT = 'get-daily-transactions-report',
    GET_ENTITY_TREE = 'get-entity-tree',
    GET_CONFIGURATOR_VALUES = 'get-configurator-values',
    UPDATE_CONFIGURATOR_VALUES = 'update-configurator-values',
    DISABLE_API_KEY = 'disable-api-key',
    GET_USER_TXN_DETAIKS_BY_TXNID = 'get-user-txn-details-by-txnid',
    NEGATIVE_SCREENING_KYB = 'rt-main-entity-kyc',
    GET_KEYWORD_SEARCH_LIST = 'get-keyword-search-list',
    GET_HELP_MENU_DOCS = 'get-help-menu-docs',
    GET_APPROVED_TRANSACTIONS = 'get-approved-transactions-by-self',
    GET_PENDING_TRANSACTIONS_BY_IBAN = 'get-user-transactions-by-entityId-pending',
    GET_KYC_UPLOAD_APPROVAL_PENDING_MAIN = 'kyc-upload2approval-pending-main',
    MAIN_USER_KYC_SCREENING = 'rt-main-user-kyc',
    GET_MERCHANT_CORPORATE_DETAILS = 'get-merchant-corporate-details',
    INSERT_CORP_KYB_DOCUMENT_STATUS = 'corp360-insert-kyb-document-status',
    GET_SIGNED_FILE_URL_CORP = 'corp360-upload-company-file-pre-signed-url',
    CREATE_IBAN_BY_STAFF_USER = 'corp360-create-account',
    CORP360_CREATE_CORP_PRIMARY_USER = 'corp360-create-merchant-user',
    GET_RECONCILIATION_REPORT = 'get-reconciliation-report',
    GET_REAL_ACCOUNT_LIST = 'get-real-account-list',
    GET_USER_STATE_STATUS = 'get-user-state-status',
    CHANGE_USER_STATE = 'change-user-state-status',
    GET_REAL_VIRTUAL_ACCOUNT_LIST = 'get-real-virtual-accounts-list',
    GET_KYC_AGING_REPORT = 'get-kyc-aging-report',
    GET_ALL_CORPORATE_MERCHANT = 'get-all-merchant-corporate',
    GET_APIGATEWAY_LOGS = 'get-apigatewaylogs',
    DISABLE_ENABLE_USER_ACCESS = 'disable-enable-user',
    PENDING_USER_OG_TXN = 'get-pending-user-og-txns-report',
    ADD_FEE_SHARE = 'create-feeshare',
    GET_FEE_SHARE_LIST = 'get-feeshare',
    GET_FEE_SHARE_FEE_ACCOUNTS = 'get-feeshare-from-and-to-ibans',
    DELETE_FEE_SHARE = 'delete-feeshare',
    CLOSE_ENTITY = 'close-entity',
    SAVE_NOTIFICATION_SETTINGS = 'insert-credit-debit-notifications',
    GET_USER_SAVED_NOTIFICATIONS = 'get-credit-debit-notifications',
    DELETE_IBAN_NOTIFICATION = 'delete-credit-debit-notifications',
    GET_BULK_TRANSACTION_STATUS = 'get-bulk-transaction-status-report',
    GET_ENTITY_KYC_SCREENING_QUESTIONS = 'get-entity-screening-questions',
    GET_SCREENING_QUESTIONS = 'get-screening-questions',
    SAVE_KYC_SCREENING_ANSWERS = 'insert-users-screening-questions',
    GET_RECEIPTS_FOR_APPROVAL = 'get-receipt-approval',
    APPROVE_RECEIPT_TRANSACTION = 'approve-credit-transactions',
    REJECT_RECEIPT_TRANSACTION = 'credit-receipt-reject',

}

export enum CorporateSignupFlow {
    USER_DETAILS = 'userDetails',
    ENTITY_INFO = 'entityInfo',
    ADDRESS = 'address',
    SMTP_INFO = 'smtpInfo',
    ADDITIONAL_INFO = 'additionalInfo',
}

export enum StatusCode {
    OKAY = 200,
    CREATED = 201,
    BAD_REQUEST = 400,
    NOT_FOUND = 404,
    INTERNAL_ERROR = 500,
}

export enum UserState {
    KYC_UPLOAD_PENDING = 'kyc-upload-pending',
    KYC_APPROVAL_PENDING = 'kyc-approval-pending',
    KYC_APPROVAL_COMPLETE = 'kyc-approval-complete',
    KYC_RENEWAL_UPLOAD_PENDING = 'kyc-renewal-upload-pending',
    KYC_EXPIRED = 'kyc-expired',
    ONBOARDING_INCOMPLETE = 'incomplete',
    ONBOARDING_COMPLETE = 'complete',
    ONBOARDING_APPROVED = 'approved',
    KYB_UPLOAD_PENDING = 'kyb-upload-pending',
    KYB_APPROVAL_PENDING = 'kyb-approval-pending',
    KYB_APPROVAL_COMPLETE = 'kyb-approval-complete',
    BANK_ACCOUNT_REQUESTED = 'bank-account-requested',
    NEGATIVE_SCREENING_SUCCESS = 'negative-screening-success',
    NEGATIVE_SCREENING_ACCOUNT_REQUESTED = 'negative-screening-account-requested',
}

export enum CurrencySynbol {
    USD = '$',
    GBP = '£',
    EUR = '£',
    RUP = '₹',
}
