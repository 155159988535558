import { Box } from '@mui/material'
import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import './style.css'

const Loader: React.FC = () => {
    return <div className="loader mx-auto mt-5"></div>
}

export default Loader

interface Props {
    text: string
}

export const LoaderWithText: React.FC<Props> = ({ text }) => {
    return (
        <div className="text-center">
            <div className="loader mx-auto mt-5"></div>
            <h6 className="mt-3 mb-5">{text && text}</h6>
        </div>
    )
}
interface ItemLoadingProps {
    loading: boolean
}
export const ItemLoader: React.FC<ItemLoadingProps> = ({ loading }) => {
    return (
        <Box display="flex" justifyContent="center">
            <ClipLoader color={'#5273FE'} loading={loading} size={25} aria-label="Loading Spinner" />
        </Box>
    )
}
