import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
interface Props {
    open: boolean
    timer: number
    setOpenOneMinRemainingModal: any
    forceLogout: any
}

const SessionExpireWarning: React.FC<Props> = ({ open, timer, setOpenOneMinRemainingModal, forceLogout }) => {
    return (
        <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle id="alert-dialog-title">Session expire warning</DialogTitle>
            <DialogContent>
                <p style={{ fontSize: '18px' }} id="alert-dialog-description">
                    Your session will expire in {timer <= 0 ? 0 : timer} seconds.
                </p>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="contained"
                    autoFocus
                    onClick={() => {
                        forceLogout()
                    }}
                >
                    Force Logout
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    autoFocus
                    onClick={() => setOpenOneMinRemainingModal({ shopPopUp: false, alertNumber: 1 })}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SessionExpireWarning
