import { DecodedToken, LoginData } from 'use/Interface/Interface'

const jwt = require('jsonwebtoken')

let baseUrl = 'https://aws-wallet.cfsemoney.com'

if (process.env.REACT_APP_ENV === 'development') {
    baseUrl = 'https://aws-wallet.cfsemoney.com'
}

if (process.env.REACT_APP_ENV === 'staging') {
    baseUrl = 'https://stage-wallet.cfsemoney.com'
}

if (process.env.REACT_APP_ENV === 'production') {
    baseUrl = 'https://prod-wallet.cfsemoney.com'
}

export const BASE_URL = baseUrl

export const REACT_APP_PROXY_WHITE_LABEL = process.env.REACT_APP_PROXY_WHITE_LABEL

export const getWhiteLabel = () => {
    if (window.location.host.includes('localhost')) {
        return REACT_APP_PROXY_WHITE_LABEL
    }

    return window.location.host
}

export const isAuthenticated = (loginData = {} as LoginData) => {
    if (Object.keys(loginData).length > 0) {
        const decoded_token: DecodedToken = jwt.decode(loginData.access_token)
        if (decoded_token && decoded_token.exp && decoded_token.exp * 1000 > Date.now()) {
            return true
        }
        localStorage.removeItem('loginData')
        sessionStorage.removeItem('loginData')

        return false
    }
    return false
}

export const getLoginData = () => {
    const loginDataString = sessionStorage.getItem('loginData')
    if (!loginDataString) {
        return {} as LoginData
    }
    return JSON.parse(loginDataString) as LoginData
}

export const getLoginDataFromLocalStorage = () => {
    const loginData = getLoginData()
    loginData['login_time'] = Date.now()

    return loginData
}

export const getMenuSubmenuFromLocalStorage = () => {
    const loginData = getLoginData()
    return loginData['menuSubMenu']
}

export const getUserState = () => {
    const loginData = getLoginData()
    const { user_state } = loginData
    return user_state ? user_state.split(' ').join('-') : ''
}

export const getKYBState = () => {
    const loginData = getLoginData()
    const { kybStatus } = loginData
    return kybStatus?.split(' ').join('-')
}

export const getOnboardingState = () => {
    return getLoginData().onboardingStatus
}

export const CORP_WALLET_USER_PROFILE_LIST = ['wallet_user', 'corporate_admin', 'corporate_staff']

export const STAFF_USERS_LIST = ['pm_admin']

export const getEntityIdFromParent = (parent: { owner: any; pm: any; spm: any; corp: any }) => {
    let entityId = parent.owner

    if (parent.pm) {
        entityId = parent.pm
    }
    if (parent.spm) {
        entityId = parent.spm
    }
    if (parent.corp) {
        entityId = parent.corp
    }

    return entityId as number
}
