import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import PasswordExpireWarning from 'components/PasswordExpireWarning/PasswordExpireWarning'
import SessionExpireWarning from 'components/SessionExpireWarning/SessionExpireWarning'
import moment from 'moment'
import { createContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    BASE_URL,
    getLoginDataFromLocalStorage,
    getMenuSubmenuFromLocalStorage,
    isAuthenticated,
} from 'use/Common/Common'
import { ApiEndpoint, StatusCode } from 'use/Enum/Enum'
import { AccountsFulfillmentInterface, LoginData, UserProfileInterface } from 'use/Interface/Interface'
import { universalPostRequestWithData } from 'use/RequestHandler/RequestHandler'
import { ContextType } from './type'
export const Context: any = createContext({})

//const REFRESH_THRES = 10 * 60 //seconds (min * seconds)
const REFRESH_THRES = 0 //seconds (min * seconds)
const SESSION_EXPIRY_THRES = 30 * 60 * 1000 //milliseconds (min * seconds * milliseconds)
const MOUSE_MOVEMENT_THRES = 4000 //milliseconds
const PASSWORD_EXPIRE_WARNING = 45 //days
const FORCE_PASSWORD_CHANGE = 50 //days
const ALLOWED_PATHS = [
    '/',
    '/login',
    '/configure-mfa',
    '/verify-mfa',
    '/reset-password',
    '/kyc-info',
    '/complete-registration',
    '/kyb-info',
    '/validate-kyb',
]

const refreshTheToken = async (username: string, refresh_token: string) => {
    const url = `${BASE_URL}/${ApiEndpoint.REFRESH_TOKEN}`
    const data = {
        username,
        refresh_token,
    }

    try {
        const response: any = await universalPostRequestWithData(url, data)
        if (response && response.status === StatusCode.OKAY) {
            const data = response.data.message
            data['expiry_time'] = Date.now() + data.expires_in * 1000
            data['login_time'] = Date.now()
            return data
        }
        return null
    } catch {
        return null
    }
}

const isAuthorized = (permissions: string[], pathname: string) => {
    if (!permissions || !pathname || ALLOWED_PATHS.includes(pathname)) {
        return true
    }
    const route = pathname.replace('/', '')
    return permissions.includes(`view ${route}`)
}

export const ContextProvider = (props: { children: any }) => {
    // Self usage
    const [openTimeoutModal, setOpenTimeoutModal] = useState(false)
    const [openOneMinRemainingModal, setOpenOneMinRemainingModal] = useState({
        alertNumber: 0,
        shopPopUp: false,
    })
    const history = useHistory()

    // Global usage
    const [loginData, setLoginData] = useState(getLoginDataFromLocalStorage() as LoginData)
    const [authenticated, setAuthenticated] = useState(isAuthenticated(loginData))
    const [openModal, setOpenModal] = useState(false)
    const [component, setComponent] = useState('')
    const [settingsScreenComponent, setSettingsScreenComponent] = useState('user-info-status')
    const [passwordExpireWarning, setPasswordExpireWarning] = useState({
        status: false,
        numberOfDaysRem: 0,
        oneTimeShow: false,
    })
    const [forcePasswordChange, setForcePasswordChange] = useState(false)
    const [accountInfoList, setAccountInfoList] = useState([] as AccountsFulfillmentInterface[])
    const [menuSubMenu, setMenuSubMenu] = useState(getMenuSubmenuFromLocalStorage() ?? ({} as string[]))
    const [userProfile, setUserProfile] = useState({} as UserProfileInterface)

    let prevTime = loginData?.login_time
    const [expiryTime, setExpiryTime] = useState<any>(undefined)
    const location = useLocation()
    let timeoutPopUp = useRef<any>()
    let intervalHandle = useRef<any>()

    const checkPasswordExpiry = () => {
        const pc_dt = moment(loginData.password_changed_dt)
        const cu_dt = moment()
        const passwordDateDiff = cu_dt.diff(pc_dt, 'days')

        if (passwordDateDiff >= FORCE_PASSWORD_CHANGE) {
            setForcePasswordChange(true)
        } else if (passwordDateDiff >= PASSWORD_EXPIRE_WARNING) {
            setPasswordExpireWarning({
                status: true,
                numberOfDaysRem: FORCE_PASSWORD_CHANGE - passwordDateDiff,
                oneTimeShow: true,
            })
        }
    }

    const values: ContextType = {
        authenticated,
        setAuthenticated,
        loginData,
        setLoginData,
        openModal,
        setOpenModal,
        component,
        setComponent,
        accountInfoList,
        setAccountInfoList,
        menuSubMenu,
        setMenuSubMenu,
        passwordExpireWarning,
        setPasswordExpireWarning,
        checkPasswordExpiry,
        forcePasswordChange,
        setForcePasswordChange,
        settingsScreenComponent,
        setSettingsScreenComponent,
        userProfile,
        setUserProfile,
    }

    useEffect(() => {
        setAuthenticated(isAuthenticated(loginData))
        if (!isAuthorized(loginData.permissions, location.pathname)) {
            history.push('/')
        }
        if (!authenticated) {
            clearTimeout(timeoutPopUp.current)
        }
    }, [authenticated, loginData, location.pathname, history])

    useEffect(() => {
        if (authenticated && !openTimeoutModal && loginData.expiry_time) {
            const expireSecond = Math.floor((loginData.expiry_time - Date.now()) / 1000)

            timeoutPopUp.current = setTimeout(() => {
                const e = Math.floor((loginData.expiry_time - Date.now()) / 1000)
                setExpiryTime(e)
                setOpenOneMinRemainingModal({
                    shopPopUp: true,
                    alertNumber: 1,
                })

                intervalHandle.current = setInterval(() => {
                    if (expireSecond > 0) {
                        setExpiryTime((oldTime) => oldTime - 1)
                    }
                }, 1000)
            }, (expireSecond - 60) * 1000)
        }

        return () => {
            clearTimeout(timeoutPopUp.current)
            clearInterval(intervalHandle.current)
            setPasswordExpireWarning({
                status: false,
                numberOfDaysRem: 0,
                oneTimeShow: false,
            })
        }
    }, [authenticated, loginData.expiry_time])

    useEffect(() => {
        if (expiryTime <= 0) {
            setOpenOneMinRemainingModal({
                shopPopUp: false,
                alertNumber: 0,
            })
            setExpiryTime(undefined)
            sessionStorage.removeItem('loginData')
            clearTimeout(timeoutPopUp.current)
            clearInterval(intervalHandle.current)
            setOpenTimeoutModal(true)
        }
    }, [expiryTime])

    const handleSession = async () => {
        if (!authenticated || !prevTime) {
            return
        }

        const currentTime = Date.now()
        const remainingTime = Math.floor((loginData.expiry_time - prevTime) / 1000)

        if (currentTime - prevTime > loginData.expiry_time) {
            sessionStorage.removeItem('loginData')
            setOpenTimeoutModal(true)
            return
        }

        if (currentTime - prevTime > MOUSE_MOVEMENT_THRES) {
            prevTime = currentTime

            if (remainingTime < REFRESH_THRES) {
                sessionStorage.removeItem('loginData')
                setOpenTimeoutModal(true)
                // const response: any = await refreshTheToken(loginData.username, loginData.refresh_token)
                // if (response) {
                //     setLoginData({ ...loginData, ...response })
                //     sessionStorage.setItem('loginData', JSON.stringify({ ...loginData, ...response }))
                // } else {
                //     sessionStorage.removeItem('loginData')
                //     setOpenTimeoutModal(true)
                // }
            }
        }
    }

    const forceLogout = () => {
        setOpenOneMinRemainingModal({
            shopPopUp: false,
            alertNumber: 0,
        })
        clearTimeout(timeoutPopUp.current)
        clearInterval(intervalHandle.current)
        setAuthenticated(false)
        handleLogout()
        setLoginData({} as LoginData)
        sessionStorage.removeItem('loginData')
    }

    const handleLogout = async () => {
        const url = `${BASE_URL}/${ApiEndpoint.LOGOUT}`
        const data = {}
        const headers = {
            Authorization: `Bearer ${loginData.access_token}`,
        }

        await universalPostRequestWithData(url, data, headers)
    }

    return (
        <div onMouseMove={handleSession}>
            <Context.Provider value={values}>{props.children}</Context.Provider>

            <Dialog open={openTimeoutModal} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle style={{ backgroundColor: red[400], paddingTop: 12, paddingBottom: 5 }}>
                    <h5 style={{ color: 'white' }}>Session expired</h5>
                </DialogTitle>
                <DialogContent sx={{ marginTop: 2 }}>
                    <Typography variant="body1">
                        Your current session has expired. Please login again to continue.
                    </Typography>
                </DialogContent>

                <DialogActions sx={{ paddingRight: 10, marginBottom: 2 }}>
                    <Button
                        color="primary"
                        variant="contained"
                        disableRipple
                        autoFocus
                        onClick={() => {
                            setOpenOneMinRemainingModal({
                                shopPopUp: false,
                                alertNumber: 0,
                            })
                            clearTimeout(timeoutPopUp.current)
                            clearInterval(intervalHandle.current)
                            setLoginData({} as LoginData)
                            setOpenTimeoutModal(false)
                            history.push('/login')
                        }}
                    >
                        Login
                    </Button>
                </DialogActions>
            </Dialog>

            {openOneMinRemainingModal.shopPopUp && authenticated && (
                <SessionExpireWarning
                    open={openOneMinRemainingModal.shopPopUp}
                    timer={expiryTime}
                    setOpenOneMinRemainingModal={setOpenOneMinRemainingModal}
                    forceLogout={forceLogout}
                />
            )}

            {authenticated && location.pathname !== '/change-password' && !loginData?.password_warning_accept && (
                <PasswordExpireWarning
                    open={passwordExpireWarning.status || forcePasswordChange}
                    forcePasswordChange={forcePasswordChange}
                    setForcePasswordChange={setForcePasswordChange}
                    passwordExpireWarning={passwordExpireWarning}
                    setPasswordExpireWarning={setPasswordExpireWarning}
                    loginData={loginData}
                    setLoginData={setLoginData}
                    forceLogout={forceLogout}
                />
            )}
        </div>
    )
}
