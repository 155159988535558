import { useEffect, useState } from 'react'
import { BASE_URL } from 'use/Common/Common'
import { ApiEndpoint, StatusCode } from 'use/Enum/Enum'
import { universalGetRequestWithParams } from 'use/RequestHandler/RequestHandler'

const fetchVersionNumber = async (setVersion: React.Dispatch<React.SetStateAction<string>>) => {
    const url = `${BASE_URL}/${ApiEndpoint.GET_PARAMETER}`
    const params = {
        param_name: 'software-version',
    }
    const response: any = await universalGetRequestWithParams(url, params)
    if (response && response.status === StatusCode.OKAY) {
        const version = response.data.data
        setVersion(version)
        localStorage.setItem('version', version)
    }
}

const Footer: React.FC = () => {
    const [version, setVersion] = useState(localStorage.getItem('version') || '')

    useEffect(() => {
        fetchVersionNumber(setVersion)
    }, [])

    return (
        <h6 className="footer">
            <b>&copy; {version}</b>
        </h6>
    )
}

export default Footer
