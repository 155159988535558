import HelpIcon from '@mui/icons-material/Help'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PersonIcon from '@mui/icons-material/Person'
import { Badge, Chip } from '@mui/material'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import logo from 'assets/logo.png'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { BASE_URL, getWhiteLabel } from 'use/Common/Common'
import { Context } from 'use/Context/ContextApi'
import { ContextType } from 'use/Context/type'
import { ApiEndpoint, StatusCode } from 'use/Enum/Enum'
import { LoginData, NotificationInterface } from 'use/Interface/Interface'
import { universalGetRequestWithParams, universalPostRequestWithData } from 'use/RequestHandler/RequestHandler'
import Announcement from './Announcement'
import './index.scss'

const determineUserProfile = (profile: string = '') => {
    if (profile && (profile.includes('wallet') || profile.includes('corporate'))) return 'user'

    return profile.split('_').pop() || ''
}

const Navbar = () => {
    const logoPlacement = 'left'
    const history = useHistory()
    const location = useLocation()

    const [data, setData] = useState([] as NotificationInterface[])
    const [systemMessage, setSystemMessage] = useState('')
    const [showSystemMessage, setSystemShowMessage] = useState(false)
    const [showWhiteLabelMessage, setShowWhiteLabelMessage] = useState(false)
    const [whiteLabelMessage, setWhiteLableMessage] = useState('')
    const { authenticated, setAuthenticated, loginData, setLoginData, setSettingsScreenComponent, setOpenModal } =
        useContext(Context) as ContextType
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)
    const anchorRef1 = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        const getEntityNotifications = async () => {
            const url = `${BASE_URL}/${ApiEndpoint.GET_ENTITY_LEVEL_NOTIFICATIONS}`

            const headers = {
                Authorization: `Bearer ${loginData.access_token}`,
            }

            const response: any = await universalGetRequestWithParams(url, {}, headers)
            if (response && response.status === StatusCode.OKAY) {
                if (response.data.data) {
                    if (loginData.current_profile !== 'wallet_user') {
                        setData(response.data.data)
                    }
                }
            }
        }
        if (authenticated) {
            getEntityNotifications()
        }
    }, [authenticated])

    const handleToggle = () => {
        setOpen1(false)
        setOpen((prevOpen) => !prevOpen)
    }

    const handleToggle1 = () => {
        setOpen(false)
        setOpen1((prevOpen) => !prevOpen)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClose1 = () => {
        setOpen1(false)
    }

    useEffect(() => {
        fetchSiteMessage()
        fetchWhiteLabelMessage()
    }, [])

    const handleLogout = async () => {
        const url = `${BASE_URL}/${ApiEndpoint.LOGOUT}`
        const data = {}
        const headers = {
            Authorization: `Bearer ${loginData.access_token}`,
        }

        await universalPostRequestWithData(url, data, headers)
    }

    const fetchSiteMessage = async () => {
        const url = `${BASE_URL}/${ApiEndpoint.GET_PARAMETER}`
        const params = {
            param_name: 'systemwide_message',
        }

        const response: any = await universalGetRequestWithParams(url, params)
        if (response && response.status === StatusCode.OKAY) {
            setSystemMessage(response.data.data)
            setSystemShowMessage(true)
        }
    }

    const fetchWhiteLabelMessage = async () => {
        const url = `${BASE_URL}/${ApiEndpoint.GET_PARAMETER}`
        const params = {
            param_name: `${getWhiteLabel()}_message`,
        }

        const response: any = await universalGetRequestWithParams(url, params)
        if (response && response.status === StatusCode.OKAY) {
            if (response.data.data) {
                setWhiteLableMessage(response.data.data)
                setShowWhiteLabelMessage(true)
            }
        }
    }

    return (
        <div>
            <nav className="navbar navbar-light bg-light p-2 ps-3">
                <Link className={`navbar-brand w-50 logo-${logoPlacement}`} to="/">
                    <img src={logo} height="80" alt="Logo" />
                </Link>

                {authenticated && !['/reset-password', '/configure-mfa', '/verify-mfa'].includes(location.pathname) && (
                    <div style={{ zIndex: 2 }} className="authenticated-user">
                        <div className="user-info">
                            <span>User id: {loginData?.username}</span>
                            <span>Profile: {determineUserProfile(loginData?.current_profile || '')}</span>
                            {(loginData.current_profile || '').includes('corporate') && (
                                <span>Corporate name: {loginData.entity_name}</span>
                            )}
                        </div>

                        {loginData?.current_profile !== 'wallet_user' &&
                            !loginData?.current_profile?.includes('corporate') && (
                                <Button ref={anchorRef1} onClick={handleToggle1}>
                                    <Badge badgeContent={data.length} color="secondary" invisible={!data.length}>
                                        <NotificationsIcon fontSize="large" className="clickable-text" />
                                    </Badge>
                                </Button>
                            )}

                        <Popper
                            open={open1}
                            anchorEl={anchorRef1.current}
                            role={undefined}
                            transition
                            disablePortal
                            nonce={undefined}
                            onResize={undefined}
                            onResizeCapture={undefined}
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose1}>
                                            <MenuList autoFocusItem={open} id="menu-list-grow">
                                                {data.map((item: NotificationInterface) => (
                                                    <MenuItem
                                                        onClick={() => {
                                                            history.push(item.pageName)
                                                            setOpen1(false)
                                                        }}
                                                    >
                                                        <div className="pe-5 flex-box-space-between">
                                                            <span className="me-3">{item.description}</span>
                                                            <Chip
                                                                color="primary"
                                                                label={`+ ${item.approvalsWaiting}`}
                                                            />
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        <Button ref={anchorRef} onClick={handleToggle}>
                            <PersonIcon fontSize="large" className="clickable-text" />
                        </Button>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            nonce={undefined}
                            onResize={undefined}
                            onResizeCapture={undefined}
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList autoFocusItem={open} id="menu-list-grow">
                                                {/* <MenuItem
                                                    onClick={() => {
                                                        if (loginData.current_profile !== 'wallet_user') {
                                                            setComponent('user-info')
                                                        } else {
                                                            history.push('settings')
                                                        }
                                                        setOpen(false)
                                                    }}
                                                >
                                                    Settings
                                                </MenuItem> */}

                                                {!loginData.oc_admin && process.env.REACT_APP_ENV === 'development' ? (
                                                    <MenuItem
                                                        onClick={() => {
                                                            history.push('/contact-support')
                                                            setOpen(false)
                                                        }}
                                                    >
                                                        Contact support
                                                    </MenuItem>
                                                ) : null}
                                                <MenuItem
                                                    onClick={() =>
                                                        history.push(
                                                            loginData?.current_profile?.includes('corporate')
                                                                ? '/settings'
                                                                : '/profile-setting'
                                                        )
                                                    }
                                                >
                                                    Profile setting
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        setAuthenticated(false)
                                                        handleLogout()
                                                        setLoginData({} as LoginData)
                                                        localStorage.removeItem('loginData')
                                                        sessionStorage.removeItem('loginData')
                                                        setOpen(false)
                                                        setOpenModal(false)
                                                        setSettingsScreenComponent('')
                                                    }}
                                                >
                                                    Logout
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        <Button onClick={() => history.push('/help')}>
                            <HelpIcon fontSize="large" className="clickable-text" />
                        </Button>
                    </div>
                )}
            </nav>

            {showSystemMessage && systemMessage && <Announcement message={systemMessage} severity="info" />}
            {showWhiteLabelMessage && whiteLabelMessage && <Announcement message={whiteLabelMessage} severity="info" />}
        </div>
    )
}

export default Navbar
