import { orange } from '@mui/material/colors'
import { Theme, createTheme } from '@mui/material/styles'

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#2B4BF2',
        },
        secondary: {
            main: orange[500],
        },
        error: {
            main: '#ef5350',
            light: '#ef5350',
            dark: '#c62828',
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: 10,
                },
            },
        },
    },
})
export default theme
// 5273FE
